import React from "react"
import Header from "../components/header"
import FisherMap from "../components/fisher-map"
import Footer from "../components/footer"
import Explore from "../components/explore"

import story from "../images/story.jpg"
//import news1 from "../images/news1.jpg"
//import news2 from "../images/news2.jpg"

const IndexPage = () => (
  <div id="indexPage">
    <Header/>

    <FisherMap/>

    <div className="content-pad">
      <div className="columns-2 big-margin-bottom">
        <div>
          <h2 style={{textAlign: 'left', margin: '0 0 25px 0'}}>Chi siamo</h2>
          <p>
            FISH MED NET (FISHERY MEDITERRANEAN NETWORK) è un progetto euro-mediterraeno che mira
            a sviluppare l'integrazione e la diversificazione del settore della pesca restituendogli
            una nuova dimensione. Il progetto è finanziato dall'Unione Europea nell'ambito del Programma ENI CBC MED.
          </p>
          <p>
            Nel Mediterraneo, la gestione sostenibile delle risorse ittiche è una grande sfida la cui
            soluzione non può essere rimandata. Al giorno d'oggi, la frammentazione dell'industria
            della pesca frena considerabilmente la dinamicità di questo settore economico chiave
            e l'interesse delle giovani generazioni verso la pesca è in caduta libera!
          </p>
          <p>
            Il progetto FISH MED NET coinvolge 7 partner in cinque paesi del Mediterraneo:
            Francia, Italia, Palestina, Libano e Tunisia, e supporta le imprese del settore
            della pesca nel Mediterraneo concentrandosi su obiettivi definiti in quattro tematiche,
            tra i quali primeggia lo sviluppo delle micro e medie piccole imprese.
          </p>
          <p>
            Il trasferimento tecnologico e l'innovazione, insieme alla  professionalizzazione di giovani
            e donne sono anche temi affrontati da questo progetto, con l'obiettivo di rivitalizzare
            il settore della pesca, renderlo più attraente per i giovani, rafforzare le competenze
            e facilitare l'accesso al lavoro attraverso il supporto professionale.
          </p>
          <p>
            Attraverso le nuove attività di business il progetto supporta i pescatori nel divenire
            guardiani del mare, contribuendo all'Obiettivo di Sviluppo Sostenibile n° 14:
            "Conservare e utilizzare in modo durevole gli oceani, i mari e le risorse marine per uno sviluppo sostenibile".
          </p>
        </div>
        <img src={story}/>
      </div>

  	  <h2>Entra a far parte della comunità FISH MED NET</h2>
	    <h3>Contatta gli sportelli di consulenza</h3>
      <p>
        FISH MED NET mira a raggiungere il maggior numero possibile di comunità di pescatori. Crediamo che la cooperazione tra tutti i pescatori coinvolti sia essenziale per condurre un'operazione di successo e possa solo beneficiare se questa comunità si prende cura l'uno dell'altro. Una delle responsabilità del counseling desk è quella di fornire informazioni su come entrare a far parte di
        questa comunità e diventare un modello per altri pescatori che aspirano a condurre la loro attività in completa armonia con la loro comunità locale, il tutto nel rispetto degli SDG delle Nazioni Unite.
        Per maggiori informazioni su dove e come contattare i sportelli di consulenza clicca <a href="/howto/">qui</a>.
      </p>

	    <h2>Sinergie con Progetti ENI CBC MED</h2>
      <p>
        Uno dei principi fondamentali su cui si fonda il progetto FISH MED NET è la creazione di un
        ambiente cooperativo tra i vari stakeholder, fornendo la necessaria visibilità degli
        stakeholder coinvolti a terzi. Tutti questi sforzi mirano a un mondo migliore in cui vivere, e
        allo stesso modo il progetto cerca sinergie con altri progetti ENI CBC MED che condividono
        obiettivi simili. In questa pagina è possibile visualizzare i diversi progetti che hanno sinergie
        con FISH MED NET e saperne di più su quali UN SDGs sono condivisi con ciascun
        progetto.
	    </p>
      <p><a href="/cooperation">Scopri di più</a></p>

    	<h2>Formazione FishmedNet</h2>
	    <p>
        Nell'ambito delle attività realizzate dal progetto FISHMEDNET, la formazione rappresenta uno strumento
        chiave per migliorare le competenze delle aziende e sviluppare nuove opportunità di diversificazione delle
        attività di pesca.
      </p>
      <p><a href="/training">Scopri di più</a></p>
        
	<h2>Standard Package</h2>
            <p>
	FISH MED NET esplora anche come i pescatori possono diventare imprenditori offrendo servizi alla collettività.
      </p>
      <p><a href="/standard-package">Scopri di più</a></p>

      <h2>Esplora tra le ultime proposte</h2>
    </div>
    <Explore slug="index"/>

    <Footer/>
  </div>
)

export default IndexPage
